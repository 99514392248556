import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import media from './media'
import BaiduMap from 'vue-baidu-map'
import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/style/flex.scss"
import "@/assets/iconfont/iconfont.css";
// 全局引入element
Vue.use(ElementUI);
Vue.use(BaiduMap, {
  ak: "9vgpZRx87gevELddhdXuTu8ekWMUgEoH"
})
Vue.config.productionTip = false
Vue.prototype.media = media

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
