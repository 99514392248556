<template>
  <div id="app">
    <Nav :checks="checks" @skip="skip" id="navhi" />
    <router-view class="navheight" />
    <Footer @pro="pro" />
  </div>
</template>
<script>
import Nav from "@/components/topnav.vue";
import Footer from "@/components/footer.vue";
export default {
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      property: "value",
      checks: null,
    };
  },
  methods: {
    skip(e) {
      this.checks = e;
    },
    pro() {
      if (window.location.hash != "#/product") {
        this.$router.push("/product");
        this.checks = 1;
      }
    },
    geturl() {
      let rout = window.location.hash;
      switch (rout) {
        case "#/":
          this.checks = 0;
          break;
        case "#/product":
          this.checks = 1;
          break;
        case "#/about":
          this.checks = 2;
          break;
        case "#/news":
          this.checks = 3;
          break;
        case "#/spot":
          this.checks = 4;
          break;
        case "#/contact":
          this.checks = 5;
          break;
        case "#/sales":
          this.checks = 6;
          break;
        case "#/app":
          this.checks = 7;
          break;
        case "#/ProductDetails":
          this.checks = 0;
          break;
      }
    },
  },

  mounted() {
    this.geturl();
    let url = JSON.parse(sessionStorage.getItem("url"));
    switch (url) {
      case "home":
        this.checks = 0;
        break;
      case "news":
        this.checks = 3;
        break;
      case "spot":
        this.checks = 4;
        break;
      case "pro":
        this.checks = 1;
        break;
    }
  },
  updated() {
    this.geturl();
  },
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  user-select: none;
}

.navheight {
  padding-top: 0.9rem;
}
</style>
