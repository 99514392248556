<template>
  <!-- 头部导航 -->
  <div class="topnav">
    <img src="../assets/img/logo.png" alt="" />
    <div class="titlewrap">
      <div
        class="titleitem"
        :style="checks == index ? 'color: #da251d;font-weight: 550' : ''"
        v-for="(item, index) in navtitle"
        :key="item.id"
        @click="skip(index)"
      >
        {{ item.name }}
        <div
          class="bott"
          :style="checks == index ? 'opacity: 1' : 'opacity: 0'"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checks: Number,
  },
  data() {
    return {
      navtitle: [
        {
          name: "首页",
          id: "345klj",
        },
        {
          name: "产品",
          id: "799kjhi",
        },
        {
          name: "关于我们",
          id: "314jdh",
        },
        {
          name: "行业新闻",
          id: "320jdk",
        },
        {
          name: "工程案例",
          id: "325usg",
        },

        {
          name: "联系我们",
          id: "575jck",
        },
        {
          name: "售后",
          id: "575jck2",
        },
        {
          name: "关于app",
          id: "575jck4",
        },
      ],
    };
  },
  methods: {
    skip(e) {
      this.$emit("skip", e);
      sessionStorage.setItem("url", JSON.stringify(""));
      e === 0 && this.$route.path != "/" && this.$router.push("/");
      e === 1 &&
        this.$route.path != "/product" &&
        this.$router.push("/product");
      e === 2 && this.$route.path != "/about" && this.$router.push("/about");
      e === 3 && this.$route.path != "/news" && this.$router.push("/news");
      e === 4 && this.$route.path != "/spot" && this.$router.push("/spot");
      e === 5 &&
        this.$route.path != "/contact" &&
        this.$router.push("/contact");
      e === 6 && this.$route.path != "/sales" && this.$router.push("/sales");
      e === 7 && this.$route.path != "/app" && this.$router.push("/app");
    },
  },
};
</script>
<style lang="scss" scoped>
.topnav {
  @include wihe(calc(100vw - 3.28rem));
  @include dif(space-between, center);
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 1.6rem 0 1.68rem;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0 0 6px rgb(223, 223, 223);
  img {
    display: block;
    width: 2.15rem;
  }
}
.titlewrap {
  @include dif(space-between);
  .titleitem {
    font-size: 0.16rem;
    padding: 0.35rem 0.1rem;
    color: #666;
    position: relative;
    margin-left: 0.8rem;
    transition: color 0.2s linear;
    white-space: nowrap;
    .bott {
      position: absolute;
      bottom: 0;
      left: 0;
      @include wihe(100%, 0.03rem, 0.02rem);
      background-color: #da251d;
      transition: all 0.3s linear;
    }
    &:hover {
      color: #da251d;
      cursor: pointer;
    }
  }
}
</style>