<template>
  <!-- 公司首页 -->
  <div class="home">
    <Swiper :statu="statu" />
    <div class="plan df jc fc" :class="{ planMobile: flag == true }">
      <div class="bg"></div>
      <div class="show df fc">
        <div class="df">
          <img class="show-img" src="http://odristech.com/img/odris.08bde7eb.png" alt="" />
          <div class="df jc fx">
            <div class="show-box df fc aie">
              <div class="df jc aic">
                <img class="logo-img" src="@/assets/img/logo.png" alt="" />
              </div>
              <div class="df je">
                <div class="show-title">
                  科技赋能智造，智造创新模式 模式创造效益，效益普惠社会
                </div>
              </div>
              <div class="show-text">
                四川奥德瑞斯智能科技发展有限公司成立于2020年9月，地处素有中国“西部硅谷”和中国科技城美誉的——绵阳，是一家在智能制造装备领域集咨询、研发、生产、销售、集成服务于一体的综合型、科技型企业。
              </div>
              <div class="show-more df je aic">
                <div class="show-more-text df jc aic" @click="goCompany">
                  了解更多
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="show-list fx df">
          <div class="df fc jb aic fx show-list-item" v-for="(item, index) in arr" :key="item.num">
            <div class="iconfont icon">
              <span v-if="index == 0">&#xe7f4;</span>
              <span v-if="index == 1">&#xe613;</span>
              <span v-if="index == 2">&#xe603;</span>
              <span v-if="index == 3">&#xe63f;</span>
            </div>
            <div class="show-list-text">{{ item.text }}</div>
            <div class="show-list-num">
              <span>{{ item.num }}</span>
              <span class="unit" v-if="index === 0">年</span>
              <span class="unit" v-else-if="index === 2">人</span>
              <span class="unit" v-else>项</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="newcenter" :class="{ newcenterFlag: boolean == true }">
      <div class="title">新闻中心</div>
      <div class="desc">
        <span>公司紧随时代、卓远发现，以科技赋能，创造需求、创新模式，致力于推动团餐行业智慧前行，只为更安全、更卫生、更环保和更高效</span>
      </div>
      <Newsw :swiperda="swiperda" :newswshow="newswshow" @addItem="addItem" />
      <div class="allnews" @click="allnews">全部新闻</div>
    </div>
  </div>
</template>

<script>
import Swiper from "@/components/swiper.vue";
import Newsw from "@/components/newswiper.vue";
export default {
  name: "Home",
  components: {
    Swiper,
    Newsw,
  },
  data() {
    return {
      newswshow: false,
      flag: false,
      statu: false,
      boolean: false,
      arr: [
        {
          text: "行业制造经验",
          icon: "&#xe7f4;",
          num: "20",
        },
        {
          text: "自主知识产权专利",
          icon: "&#xe613;",
          num: "40",
        },
        {
          text: "专业博士、硕士团队",
          icon: "&#xe603;",
          num: "16",
        },
        {
          text: "权威认证",
          icon: "&#xe63f;",
          num: "8",
        },
      ],
      swiperda: [
        {
          id: "qwe",
          arr: [
            {
              img: [
                require("@/assets/img/n2306101.jpg"),
                require("@/assets/img/n2306102.jpg"),
                require("@/assets/img/n2306103.jpg"),
                require("@/assets/img/n2306104.jpg"),
              ],
              id: "54spypaq",
              title: "中国食品药品企业质量安全促进会领导考察调研",
              text: [
                "6.10日中国食品药品企业质量安全促进会领导到四川奥德瑞斯智能科技发展有限公司考察调研。",
                "李  健,北京工商大学食品与健康学院副院长,中国食品药品企业质量安全促进会,预制菜与智慧团餐专委会 秘书长;\n陶  庆,中国食品药品企业质量安全促进会,预制菜与智慧团餐专委会 常务秘书长,中融百鸣投资管理集团有限公司董事长。",
                "奥德瑞斯总经理周光军对来访客人表示了热烈的欢迎与诚挚的感谢，并简要就产品理念、研发技术、科技创新性、应用场景、食品安全、发展规划及市场前景方面进行介绍并实地参观了智能售餐机器人生产车间。",
              ],
              date: "2023-6-10",
            },
            {
              img: [
                require("@/assets/img/n2305251.jpg"),
                require("@/assets/img/n2305252.jpg"),
                require("@/assets/img/n2305253.jpg"),
                require("@/assets/img/n2305254.jpg"),
                require("@/assets/img/n2305255.jpg"),
              ],
              id: "54cjrhyh",
              title: "“产教融合”之“师生进企业”研学活动",
              text: [
                "5.25日绵阳职业技术学院师生到四川奥德瑞斯智能科技发展有限公司参观学习。奥德瑞斯总经理对学院师生的来访表示了热烈的欢迎！",
                "总经理简要就产品理念、研发技术、科技创新性、应用场景、发展规划及市场前景方面进行介绍。学院师生在公司总经理等的陪同下，实地参观了智能售餐机器人生产车间。学院师生对智能售餐机器人标准化、现代化、机械化、高效智能产生极大的兴趣，并感叹高科技带来的便捷高效！",
                "参观企业是一次近距离与企业面对面对话的机会，了解企业的发展，了解企业的文化，帮助整日呆在校园里的学生开拓眼界，给更多有能力的学生提供更宽广的平台。这次活动将有利于提高同学们的就业意识和能力，把握市场变化，转变就业观念，树立正确的择业观。了解行业发展趋势，感知职场环境和企业文化，实现理论与实践的统一。",
              ],
              date: "2023-5-25",
            },
            {
              img: [
                require("@/assets/img/n2305101.jpg"),
                require("@/assets/img/n2305102.jpg"),
              ],
              id: "54cxddy",
              title: "成都信息工程大学领导莅临奥德瑞斯调研",
              text: [
                "5.10日成都信息工程大学自动化学院院长张葛祥等莅临四川奥德瑞斯智能科技发展有限公司调研。",
                "奥德瑞斯总经理周光军对张葛祥院长等的来访表示了热烈的欢迎与诚挚的感谢，并简要就产品理念、研发技术、科技创新性、应用场景、发展规划及市场前景方面进行介绍。专家团队在公司总经理周光军陪同下，实地参观了生产车间、智能售餐机器人。",
                "张葛祥院长表示成都信息工程大学将针对奥德瑞斯发展中的技术热点和难点问题，开展技术对接活动，对企业进行项目论证、技术咨询、技术诊断、技术攻关、技术培训等多种形式的服务工作。帮助企业解决实际需求。建立有效的专家与企业协作的长效机制，加快形成以企业技术创新为主体、市场为导向、产学研相结合的技术创新体系，促进科技成果的转化，提高企业的市场竞争能力，推动企业产品技术研发升级。双方对未来就科研项目、平台建设等方面开展合作进行了深入的探讨。",
              ],
              date: "2023-5-10",
            },
          ],
        },
        {
          id: "tjyr",
          arr: [
          {
              img: [
                require("@/assets/img/n2304261.jpg"),
                require("@/assets/img/n2304262.jpg"),
                require("@/assets/img/n2304263.jpg"),
                require("@/assets/img/n2304264.jpg"),
              ],
              id: "54fcqld",
              title: "热烈欢迎！奥德瑞斯迎接涪城区领导调研指导工作",
              text: [
                "4月26日下午，绵阳市涪城区区委副书记、区长张虚怀一行莅临四川奥德瑞斯智能科技发展有限公司调研指导工作，实地考察企业经营发展状况，面对面、点对点帮助企业解决发展难题，持续优化营商环境。公司总经理周光军及相关负责人热情接待并汇报工作。",
                "奥德瑞斯总经理周光军就产品理念、研发技术、科技创新性、应用场景、发展规划及市场前景方面进行详尽介绍，双方就目前面临的困难、期待的政策支持、对行业发展的建议等内容进行了充分交流。",
                "随后，总经理周光军带领领导一行参观了生产车间及智能售餐设备。张虚怀区长对公司产品及市场前景表示高度认可，希望企业要进一步把握市场机遇，主动适应市场需求变化。",
                "最后，张区长表示政府一定着力解决企业在扩大生产、场地、资金等方面的实际问题，为企业搭建相关平台，减少用工难、融资难问题。事后，涪城区工业园区第一时间对接我司，就厂房租赁一事进行洽谈。相信在政府支持引导下，奥德瑞斯会加强产品更新迭代、持续扩大生产，踏上高新技术企业高速发展快车道。",
              ],
              date: "2023-4-26",
            },
            {
              img: [
                require("@/assets/img/n2303081.jpg"),
                require("@/assets/img/n2303082.jpg"),
                require("@/assets/img/n2303083.jpg"),
              ],
              id: "67kdhinde",
              title: "涪城区第三届产业高质量发展大会",
              date: "2023-3-8",
              text: [
                "3月8日，涪城区第三届产业高质量发展大会召开。总结2022年全区产业发展工作和人才工作，表彰2022年度建设“四个强区”突出贡献企业（单位）、领军人才团队等，安排部署2023年产业发展重点工作和人才工作。会上，民营经济市场主体代表、区内单位代表分别围绕对标“五市战略”，深入实施“三千工程”、加快建设“四个强区”，全面建设西部现代化强区作精彩发言。",
                "四川奥德瑞斯智能科技发展有限公司荣获人才政策资金8万元。奥德瑞斯智能科技发展有限公司将聚焦建设科技强企，努力攻克关键核心技术，走好从科技强到产业强、经济强的高质量发展之路。为涪城区助力打造“五中心一枢纽”，全面建设中国科技城先行区和社会主义现代化绵阳作出新的更大贡献，奋力谱写科技强国的新篇章!",
              ],
            },
            {
              img: [
                require("@/assets/img/xnzf3.jpg"),
                require("@/assets/img/xnzf4.jpg"),
                require("@/assets/img/xnzf2.jpg"),
                require("@/assets/img/xnzf.jpg"),
              ],
              id: "87kdhinde",
              title: "西南政法大学校长参观奥德瑞斯智能售餐设备",
              date: "2022-09-25 ",
              text: [
                "9月23日下午，西南政法大学渝北校区党委副书记、校长付子堂带领院领导对奥德瑞斯智能售餐设备进行检阅，并在技术部负责人的指导下体验打餐。",
                "期间，技术负责人对智能售餐设备结构、性能、出餐速度进行简单介绍，校长及同行领导对智能售餐设备表示极大的兴趣并给予充分肯定。",
                "奥德瑞斯智能售卖设备正式引入校园，进一步拓展了为师生服务的渠道，试运行阶段，希望能为师生提供更多便利，共同为学校智能化高水平建设作出相应贡献。",
              ],
            },
          ],
        },
      ],
      products: [
        {
          flag: 0,
          title: "智能面点售卖机",
          text: `
※ 本产品专用于大型企事业集体食堂、旅游景点、机场、车站、商场等人口密集的地方,方便取食，干净卫生，避免人为二次污染，实物保鲜程度高，恒温加热加湿。
※. 产品性能稳定，自动化程度高，采用高品质微电脑变频控制面板，名牌电机电器，可根据客户要求一次可吐出1--3个不等数量（数量可定制）的馒头或包子。※※※※
※ .可刷IC卡消费，也可根据客户需求，使用场景不同，采用二维码、微信、现金消费，还可与单位小卖部或其它消费部门实行一卡通消费管理，操作方便，维护简单。
※. 可根据客户需求，实施远程监控和管理。
※. 先进的后台管理系统，可实时查看统计每天所卖食品的总数量、消费总金额、那样菜品最受欢迎等所需数据。
※ .机体与食物接触的关键性部件全部采用食品级304#不锈钢制造，面板采用无指纹304#不锈钢制作，美观大方卫生。
※. 每台机器可售卖一样面食，取代人工售卖，大大节约人力成本和体力劳动，一个人可操作管理多台机器。LED显示屏可在50米开外看清所卖食品名称及价格，还可发布公告与通知等。
※ 清洗方便，当把售卖启动开关旋转至清洗开关时，机器自动进入清洗状态，待清洗完毕后，打开水枪，把未清洗到的地方在冲洗一遍，最后把废水盘冲洗干净即可。
※ 提供免费上门培训指导、安装、调试。`,
          img: require("@/assets/img/machine.png"),
        },
        {
          flag: 0,
          title: "多功能食堂智能售菜机",
          text: `
※ 本产品专用于大型企事业集体食堂、旅游景点、机场、车站、商场等人口密集的地方，
方便取食，干净卫生，避免人为二次污染，实物保鲜程度高，最长可保温24小时。
※ 产品性能稳定，自动化程度高，采用高品质微电脑变频控制面板，名牌电机电器，自动吐盘，自动进出餐具。
※  可刷IC卡消费，也可根据客户需求，使用场景不同，采用二维码、微信、现金消费，还可与单位小卖部或其它消费部门实行一卡通消费管理，操作方便，维护简单。
※  可根据客户需求，实施远程监控和管理。
※  先进的后台管理系统，可实时查看统计每天所卖食品的总数量、消费总金额、那样菜品最受欢迎等所需数据。
※ 机体与食物接触的关键性部件全部采用食品级304#不锈钢制造，面板采用无指纹304#不锈钢制作，美观大方卫生。
※. 功能多样，用不同的储料功能桶，可售卖稀粥、豆浆、早餐小菜、炒菜、烧菜、汤菜、烩面，解决一日三餐售卖问题，大大节约人力成本和体力劳动，一个人可操作管理4-6台机器。
※ 每台机器同时可售卖三样菜品，LED显示屏可在50米开外看清所卖菜品及价格，还可发布公告与通知等。
※ 出菜品相高，保持原有菜品品相90%，不易破碎，油脂均匀，冬季保持菜品不降温。
※ 清洗方便，当把打菜开关旋转至清洗开关时，机器自动进入清洗状态，打开水枪，把水和洗洁精混合液或热水喷至清洗部位，清水冲净即可。
※ 提供免费上门培训指导、安装、调试。`,
          img: require("@/assets/img/food.png"),
        },
      ],
    };
  },
  mounted() {
    this.onmouse();
    this.swiper();
  },
  methods: {
    onmouse() {
      setTimeout(() => {
        this.statu = true;
      }, 100);
      window.onmousewheel = document.onmousewheel = (e) => {
        let herf = window.location.hash;
        if (e.wheelDelta < 0 && herf == "#/") {
          if (document.documentElement.scrollTop < 782 && this.flag == false) {
            document.documentElement.scrollTop = 782;
            this.flag = true;
            this.statu = false;
          }
          if (
            document.documentElement.scrollTop > 782 &&
            document.documentElement.scrollTop < 1725 &&
            this.boolean == false
          ) {
            document.documentElement.scrollTop = 1725;
            this.boolean = true;
            this.flag = false;
          }
        } else if (e.wheelDelta > 0 && herf == "#/") {
          if (
            document.documentElement.scrollTop > 985 &&
            document.documentElement.scrollTop <= 1825 &&
            this.boolean == true
          ) {
            document.documentElement.scrollTop = 985;
            this.boolean == false;
            this.flag = true;
          }
          if (document.documentElement.scrollTop < 985 && this.flag == true) {
            document.documentElement.scrollTop = 0;
            this.flag = false;
            this.statu = true;
            this.boolean = false;
          }
        }
      };
    },
    swiper() {
      if (this.swiperda.length > 1) {
        this.newswshow = true;
      }
    },
    allnews() {
      this.$router.push("/news");
    },
    addItem() {
      sessionStorage.setItem("url", JSON.stringify("home"));
    },
    goCompany() {
      this.$router.push("/about");
    },
    gomore(item) {
      sessionStorage.setItem("url", JSON.stringify("home"));
      this.$router.push({
        name: "ProductDetails",
        query: {
          data: item,
        },
      });
      localStorage.setItem("obj", JSON.stringify(item));
    },
    allnews() {
      this.$router.push("/news");
    },
  },
};
</script>
<style lang="scss" scoped>
.newcenter {
  padding: 0.8rem 1.6rem;
  background-color: #f6f6f6;
  opacity: 0;
  transition: opacity 2000ms;

  .title {
    font-size: 0.48rem;
    color: #333;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.1rem;
  }

  .desc {
    font-size: 0.18rem;
    text-align: center;
    margin: 0.2rem auto 0.48rem auto;
    color: #999;
    width: 43%;

    span {
      line-height: 0.32rem;
    }
  }
}

.newcenterFlag {
  opacity: 1;
}

.allnews {
  font-size: 0.18rem;
  cursor: pointer;
  @include wihe(3.35rem, 0.66rem);
  margin: 0.84rem auto 0.04rem auto;
  @include dif(center, center);
  border: 1px solid #cfcfcf;
  white-space: nowrap;
  border-radius: 0.1rem;
}

.allnews:hover {
  background: #da251d;
  color: #fff;
}

.content {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.navBar {
  width: 100%;
  position: fixed;
  background: #fff;
  top: 0;
  z-index: 1;
}

.plan {
  background: #f6f6f6;
  font-size: 0.48rem;
  opacity: 0;
  transition: opacity 2000ms;

  .plan-title {
    color: #333;
    padding: 1.02rem 0 0.25rem;
    font-weight: 600;
  }

  .plan-text {
    color: #999999;
    font-size: 0.18rem;
    padding-bottom: 0.55rem;
  }

  .plan-item {
    max-width: 4.39rem;
    background-image: linear-gradient(to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5),
        #fff);
    // padding: 0 0.44rem;
    padding: 0 1.5rem;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 0.03rem 0.07rem rgb(228, 228, 228);
    }

    img {
      width: 1.9rem;
    }
  }

  .plan-img-box {
    padding: 0 1.68rem;
    font-size: 0.28rem;
    margin-bottom: 1.23rem;
  }

  .plan-introduce {
    width: 2.56rem;
    font-size: 0.16rem;
    color: #aaaaaa;
    margin: 0.17rem 0 0.53rem;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .plan-more {
    border: 0.015rem solid #006895;
    color: #006895;
    font-size: 0.14rem;
    padding: 0.13rem 0.78rem 0.15rem 00.79rem;
  }

  .plan-more:hover {
    color: #fff;
    background: #da251d;
    border: 0.015rem solid #da251d;
    cursor: pointer;
  }

  .plan-more-box {
    padding-bottom: 0.49rem;
  }
}

.planMobile {
  opacity: 1;
}

.show {
  padding-top: 0.1rem;
  background: #fff;
  font-size: 0.34rem;

  .show-box {
    max-width: 5.01rem;
  }

  .show-img {
    width: 10.85rem;
    border-radius: 0.1rem;
  }

  .logo-img {
    width: 3.2rem;
    padding: 1rem 0 0.33rem;
  }

  .show-title {
    max-width: 4.62rem;
    padding-bottom: 0.32rem;
    text-align: right;
  }

  .show-text {
    font-size: 0.18rem;
    text-align: right;
    color: #999999;
    padding-bottom: 0.56rem;
  }

  .show-more {
    color: #fff;
    text-align: center;
    font-size: 0.14rem;
  }

  .show-more-text {
    width: 2.66rem;
    height: 0.48rem;
    color: #006895;
    border: 0.01rem solid #006895;
    cursor: pointer;
    border-radius: 0.1rem;
  }

  .show-more-text:hover {
    border: 0.01rem #da251d solid;
    background: #da251d;
    color: #fff;
  }

  .show-list-item {
    margin: 0.3rem 0;
    padding: 0.62rem 0;

    .icon {
      font-size: 0.5rem;
    }

    .unit {
      font-size: 0.2rem;
      color: #00205b;
      position: absolute;
      right: -0.5rem;
      top: 0.4rem;
    }
  }

  .show-list-item:hover {
    background: rgba(8, 121, 173, 0.8);
    border-radius: 0.15rem;
    color: #fff;

    .unit {
      color: #fff;
    }

    .show-list-num {
      color: #da251d;
    }
  }

  .show-list-text {
    font-size: 0.19rem;
    padding: 0.25rem 0 0.07rem;
  }

  .show-list-num {
    color: rgba(8, 121, 173, 0.8);
    font-size: 0.55rem;
    position: relative;
  }
}

.el-carousel-item {
  display: flex;
  flex-wrap: wrap;
}
</style>
