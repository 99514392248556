<template>
  <div class="swiper">
    <el-carousel
      arrow="never"
      height="5.075rem"
      :autoplay="true"
      indicator-position="none"
      :interval="3000"
      ref="change"
    >
      <el-carousel-item v-for="item in swiperda" :key="item.id">
        <div class="itemwerap df fw jb">
          <div
            class="item"
            v-for="ite in item.arr"
            :key="ite.id"
            @click="addItem(ite)"
          >
            <img :src="ite.img[0]" alt="" />
            <div class="newtitle">{{ ite.title }}</div>
            <div class="descs">{{ ite.text[0] }}</div>
            <div class="time">{{ ite.date }}</div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div
      class="swbtn iconfont df jc aic"
      style="left: -1.32rem"
      @click="next"
      v-show="newswshow == true"
    >
      &#xe63b;
    </div>
    <div
      class="swbtn iconfont df jc aic"
      style="right: -1.32rem"
      @click="prev"
      v-show="newswshow == true"
    >
      &#xe637;
    </div>
    <div class="swbottom">
      <div
        class="switem"
        :style="swsitch == index ? 'background-color: #DA251D;' : ''"
        v-for="(item, index) in swiperda.length"
        :key="item"
        v-show="newswshow == true"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      swsitch: 0,
    };
  },
  props: {
    swiperda: Array,
    newswshow: Boolean,
  },
  methods: {
    addItem(item) {
      this.$emit("addItem");
      this.$router.push({
        path: "/newsDetails",
        query: {
          data: item,
        },
      });
      localStorage.setItem("obj", JSON.stringify(item));
    },
    prev() {
      if (this.swsitch < this.swiperda.length && this.swsitch > 0) {
        this.$refs.change.prev();
        this.swsitch--;
      }
    },
    next() {
      if (this.swsitch < this.swiperda.length - 1 && this.swsitch >= 0) {
        this.$refs.change.next();
        this.swsitch++;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.swiper {
  position: relative;

  .swbtn {
    position: absolute;
    width: 0.82rem;
    font-size: 0.3rem;
    color: rgba(51, 134, 170, 0.8);
    height: 1.11rem;
    top: 1.6rem;
    border: 0.03rem rgba(51, 134, 170, 0.8) solid;
    border-radius: 0.1rem;
  }

  .swbtn:hover {
    background: #da251d;
    color: #fff;
    border: 0.03rem solid #da251d;
    cursor: pointer;
  }

  .swbottom {
    display: flex;
    justify-content: center;
    margin-top: 0.4rem;

    .switem {
      display: block;
      height: 3px;
      background-color: #ddd;
      border-radius: 3px;
      width: 0.96rem;
      margin-right: 0.16rem;
    }
  }

  .item {
    border: 0.035rem solid #f6f6f6;
    background: #fff;
    cursor: pointer;
    @include dif();
    flex-direction: column;
    flex-wrap: wrap;
    width: 30%;
    padding-bottom: 0.26rem;
    border-radius: 0.1rem;
    overflow: hidden;
    .descs {
      margin: 0 0.1rem 0.2rem 0.32rem;
      font-size: 0.17rem;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      color: #212121;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .newtitle {
      font-size: 0.2rem;
      font-weight: 500;
      margin: 0.2rem 0.1rem 0.2rem 0.32rem;
      color: #da251d;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    img {
      width: 100%;
      height: 3rem;
      display: block;
    }

    .time {
      font-size: 0.14rem;
      color: #da251d;
      margin-left: 0.32rem;
    }
  }
  .item:hover {
    border: 0.035rem solid rgba(51, 134, 170, 0.8);

    img {
      transform: scale(1.05);
    }
  }
}
</style>