import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import newsDetails from '../views/newsDetails.vue'
import position from "../views/position.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/newsDetails',
    name: 'newsDetails',
    component: newsDetails
  },
  {
    path: '/position',
    name: 'position',
    component: position
  },

  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "product" */ '../views/Product.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/News.vue')
  },
  {
    path: '/spot',
    name: 'spot',
    component: () => import(/* webpackChunkName: "spot" */ '../views/Spot.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },

  {
    path: '/company',
    name: 'company',
    component: () => import(/* webpackChunkName: "contact" */ '../views/company.vue')
  },
  {
    path: '/ProductDetails',
    name: 'ProductDetails',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ProductDetails.vue')
  },
  {
    path: '/sales',
    name: 'sales',
    component: () => import(/* webpackChunkName: "contact" */ '../views/sales.vue')
  },
  {
    path: '/app',
    name: 'app',
    component: () => import(/* webpackChunkName: "contact" */ '../views/app.vue')
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 期望滚动到哪个位置
    return {
      x: 0,
      y: 0
    }
  },
})

export default router
