<template>
  <!-- 底部 -->
  <div class="footer">
    <div class="gotop">
      <div class="lefts">
        <img src="../assets/img/logo.png" alt="" />
        <span
          >四川奥德瑞斯智能科技发展有限公司成立于2020年9月，地处素有中国“西部硅谷”和中国科技城美誉的—绵阳是一家在智能制造装备领域集咨询、研发、生产、销售、集成服务于一体的综合型、科技型企业。</span
        >
      </div>
      <img class="right" src="../assets/img/top.png" alt="" @click="gotop" />
    </div>
    <div class="footmsg">
      <div class="footwrap">
        <div class="item">
          <div class="title">产品服务</div>
          <div class="bott df">
            <ul class="left">
              <li v-for="item in proser" :key="item.id" @click="pro">
                {{ item.name }}
              </li>
            </ul>
            <ul class="left right">
              <li v-for="item in prosers" :key="item.id" @click="additem(item)">
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="item" v-for="item in footerdata" :key="item.name">
          <div class="title">{{ item.name }}</div>
          <ul class="left bott">
            <li v-for="ite in item.arr" :key="ite.id" @click="addname(item)">
              {{ ite.name }}
            </li>
          </ul>
        </div>
        <img class="wx" src="http://odristech.com/img/wx.45cf08cf.jpg" alt="" />
      </div>
      <div class="footbtn">
        <span
          >版权所有©四川奥德瑞斯智能科技发展有限公司。保留一切权利。蜀ICP备2021031321号</span
        >
        <div class="end">
          <span>使用条款</span>
          <span>隐私政策</span>
          <span>法律信息</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      proser: [
        {
          name: "智能售卖系列",
          id: "12uij",
        },
      ],
      prosers: [
        {
          name: "其它",
          id: "89ius",
        },
        {
          name: "售后",
          id: "89ous",
        },
      ],
      footerdata: [
        {
          name: "行业新闻",
          arr: [
            {
              name: "新闻咨询",
              id: "67khui",
            },
          ],
        },
        {
          name: "工程案例",
          arr: [
            {
              name: "部分案例",
              id: "67idjs",
            },
          ],
        },
        {
          name: "了解我们",
          arr: [
            {
              name: "企业简介",
              id: "67pcys",
            },
            {
              name: "企业文化",
              id: "67rwtd",
            },
            {
              name: "企业资质",
              id: "67pctw",
            },
          ],
        },
        {
          name: "加入我们",
          arr: [
            {
              name: "联系我们",
              id: "67ctel",
            },
          ],
        },
      ],
    };
  },
  methods: {
    gotop() {
      document.documentElement.scrollTop = 0;
    },
    pro() {
      this.$router.push("/product");
    },
    additem(item) {
      if (item.id == "89ius") this.$router.push("/product");
      if (item.id == "89ous") this.$router.push("/sales");
    },
    addname(item) {
      if (item.name == "行业新闻") this.$router.push("/news");
      if (item.name == "工程案例") this.$router.push("/spot");
      if (item.name == "了解我们") this.$router.push("/about");
      if (item.name == "加入我们") this.$router.push("/contact");
    },
  },
};
</script>
<style lang="scss" scoped>
.gotop {
  @include dif(space-between, center);
  padding: 0.66rem 1.6rem 0.56rem 1.68rem;
  background-color: #f0edf1;
  .lefts {
    width: 50%;
    text-align: justify;
    font-size: 0.14rem;
    color: #333;
    letter-spacing: 0.01rem;
    img {
      display: block;
      width: 2rem;
      margin-bottom: 0.21rem;
    }
    span {
      font-size: 0.18rem;
      line-height: 0.35rem;
    }
  }
  .right {
    width: 0.9rem;
    margin: 0;
  }
}
.footmsg {
  padding: 0.8rem 1.6rem 0 1.68rem;
  background-color: rgba(0, 104, 149, 0.8);
  .footwrap {
    @include dif(space-between);
    color: #fff;
    padding: 0 0.2rem 0.22rem 0.3rem;
    border-bottom: 1px solid #fff;
    .imgs {
      display: flex;
      align-self: flex-end;
      margin-left: 0.5rem;
      img {
        display: block;
        width: 0.22rem;
        margin-left: 0.1rem;
      }
    }
  }
  .footbtn {
    padding: 0.3rem 0;
    font-size: 0.16rem;
    opacity: 0.9;
    font-weight: lighter;
    color: #c2d7ff;
    @include dif(space-between);
    .end {
      span {
        margin-left: 0.3rem;
      }
    }
  }
}
.title {
  font-size: 0.2rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0.47rem;
}
.bott {
  font-size: 0.16rem;
  font-weight: lighter;
}
.left {
  margin-bottom: 1rem;
  list-style: none;
  li {
    margin-bottom: 0.12rem;
    cursor: pointer;
  }
}
.right {
  margin-left: 0.5rem;
  cursor: pointer;
}
.wx {
  width: 2rem;
}
</style>