<template>
  <div class="content df fc">
    <div class="main">
      <div>发布时间:<span class="date">2021-06-01</span></div>
      <div class="text">行业销售经理</div>
      <div class="df aic">
        <div>销售类</div>
        <div class="line"></div>
        <div>绵阳市·涪城区</div>
      </div>
      <div class="df describe aic">
        <div>职位描述</div>
        <div class="describe-line fx"></div>
      </div>
      <div class="describe-con">
        <div>工作职责:</div>
        <div>
          1.负责云账户综合服务平台在各个领域的拓展及客户资源挖掘;<br />
          2.独立完成新客户的开发、商务谈判等工作，完成销售目标；<br />
          3.关注客户使用情况和需求，建立良好的客户关系体系；<br />
          4.独立完成各种综合性、整合性业务的商务沟通工作；<br />
          5.有效驱动内、外部资源，及时解决客户合作中出现的问题。<br />
        </div>
      </div>
      <div>
        <div>职位要求:</div>
        <div class="describe-con">
          任职资格：<br />
          1.统招本科5年及以上互联网企业级大客户销售经验；<br />
          2.具备较强的商务谈判能力以及强烈的市场竞争意识；<br />
          3.思维敏捷，逻辑性强，有较强的学习和执行能力；<br />
          4.善于沟通，有互联网企业高层管理者资源或财务客户资源；<br />
          5.具备较强的团队协作意识及资源协调能力，责任心强。<br />
        </div>
      </div>
    </div>
    <div class="df jc aic">
      <div class="back" @click="backList">返回职位列表</div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {};
  },
  methods: {
    backList() {
      this.$router.push("/Contact");
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  padding-top: 0.9rem;
  background: #f6f6f6;
}
.main {
  margin: 0.86rem 1.68rem 0;
  padding: 0.43rem 0.76rem;
  background: #fff;
  font-size: 0.18rem;
  color: #aaaaaa;
}
.date {
  padding-left: 0.2rem;
}
.text {
  color: #333333;
  font-size: 0.28rem;
  padding: 0.14rem 0 0.22rem;
}
.line {
  width: 0.02rem;
  height: 0.18rem;
  background: #ddd;
  margin: 0 0.32rem;
}
.describe {
  padding: 0.39rem 0 0.28rem;
}
.describe-line {
  height: 0.01rem;
  background: #ddd;
  margin-left: 0.38rem;
}
.describe-con {
  line-height: 0.4rem;
}
.back {
  color: #fff;
  font-size: 0.2rem;
  background: #ff4b4b;
  padding: 0.15rem 0.38rem 0.17rem 0.36rem;
  margin: 0.43rem 0 0.57rem;
  cursor: pointer;
}
</style>