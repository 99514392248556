<template>
  <div class="content df fc" :class="{ contentClick: statu == true }">
    <div class="main fx font-s">
      <div class="details-title df jb aic">
        <div class="back df jc aic" @click="ret">返回</div>
        <div class="font-b title" v-if="obj != undefined">
          {{ this.obj.title }}
        </div>
        <div class="grey df">
          <div class="date">2021-01-02</div>
          <div><i class="el-icon-view eye"></i>5021</div>
        </div>
      </div>
      <div v-if="obj != undefined" class="details-con font-s grey df fc aic">
        <img :src="obj.img[0]" alt="" />
        <div class="text" style="white-space: pre-line">
          {{ this.obj.text[0] }}
        </div>
        <img v-if="obj.img[1] != undefined" :src="obj.img[1]" alt="" />
        <!-- <img v-if="obj.img[4] != undefined" :src="obj.img[4]" alt="" /> -->
        <div class="text" style="white-space: pre-line">
          {{ this.obj.text[1] }}
        </div>
        <img v-if="obj.img[2] != undefined" :src="obj.img[2]" alt="" />
        <img v-if="obj.img[5] != undefined" :src="obj.img[5]" alt="" />
        <img v-if="obj.img[6] != undefined" :src="obj.img[6]" alt="" />
        <div class="text" style="white-space: pre-line">
          {{ this.obj.text[2] }}
        </div>
        <img v-if="obj.img[3] != undefined" :src="obj.img[3]" alt="" />
        <div class="text" style="white-space: pre-line">
          {{ this.obj.text[3] }}
        </div>
        <img v-if="obj.img[4] != undefined" :src="obj.img[4]" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      obj: undefined,
      statu: false,
    };
  },

  mounted() {
    this.obj = this.$route.query.data;
    if (this.$route.query.data instanceof Object != true) {
      this.obj = JSON.parse(localStorage.getItem("obj"));
    }
    this.contentClick();
  },
  methods: {
    ret() {
      let url = JSON.parse(sessionStorage.getItem("url"));
      sessionStorage.setItem("url", JSON.stringify(""));
      if (url == "home") {
        this.$router.push("/");
      } else if (url == "news") {
        this.$router.push("/news");
      } else if (url == "spot") {
        this.$router.push("/spot");
      }
    },
    contentClick() {
      setTimeout(() => {
        this.statu = true;
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  background: #f6f6f6;
  opacity: 0;
  transition: opacity 2000ms;
}
.contentClick {
  opacity: 1;
}

.main {
  margin: 1.6rem 1.68rem 0.74rem;
  background: #fff;
  padding: 0.5rem 0.7rem;
}
.details-title {
  padding-bottom: 0.38rem;
  border-bottom: 0.01rem solid #cccccc;
  .date {
    padding: 0 0.51rem 0 0;
  }
  .eye {
    padding-right: 0.07rem;
  }
  .title {
    color: #00205b;
  }
  .back {
    width: 1.33rem;
    height: 0.51rem;
    background: #da251d;
    color: #fff;
    cursor: pointer;
  }
}
.font-b {
  font-size: 0.32rem;
}
.font-s {
  font-size: 0.18rem;
}

.grey {
  color: #888888;
}

.details-con {
  padding-top: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: Regular;
  text-align: left;
  line-height: 0.3rem;
  img {
    width: 6.66rem;
    height: 4.25rem;
    margin: 0.37rem 0 0.67rem 0;
  }
}
.bottom {
  background: #fff;
  font-size: 0.32rem;
  padding: 0 1.68rem 0.51rem;
  .bottom-title {
    padding: 0.31rem 0 0.35rem;
  }
  .bottom-block {
    width: 0.08rem;
    height: 0.08rem;
    background: #aaa;
    margin: 0 0.05rem;
  }
  .bottom-item {
    color: #515151;
    background: #f6f6f6;
    img {
      min-width: 3.5151rem;
      max-height: 1.8349rem;
    }
    .bottom-main {
      font-size: 0.18rem;
      max-width: 3rem;
      padding-top: 0.21rem;
    }
    .bottom-date {
      font-size: 0.16rem;
      min-width: 3rem;
      padding: 0.13rem 0 0.15rem 0;
      color: #888888;
    }
  }
  .bottom-item:hover {
    color: #00205b;
    cursor: pointer;
    .bottom-date {
      color: #00205b;
    }
  }
}
.text {
  max-width: 6.66rem;
}
</style>