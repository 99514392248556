<template>
  <div class="block" :class="{ blockClick: statu == true }">
    <el-carousel height="8.9rem" arrow="always">
      <el-carousel-item v-for="item in 3" :key="item">
        <img
          class="swiper-img"
          src="http://odristech.com/img/swiper.478249a5.png"
          alt=""
        />
      </el-carousel-item>
      <div class="swiper-text df ja">
        <div class="df fc">
          <div class="df jc aic">
            <img src="../assets/img/arrow.png" alt="" />
            <div>用科技打造未来</div>
          </div>
          <div class="swiper-eng df jc">
            L E T &nbsp;&nbsp;T H E &nbsp;&nbsp; F U T U R E &nbsp;&nbsp;B E N E
            F I T &nbsp;&nbsp;T H E &nbsp;&nbsp; W O R L D
          </div>
        </div>
        <div class="df fc">
          <div class="df jc aic">
            <img src="../assets/img/arrow.png" alt="" />
            <div>让未来普惠天下</div>
          </div>
          <div class="swiper-eng df jc">
            B U I L D &nbsp;&nbsp;T H E &nbsp;&nbsp; F U T U R E&nbsp;&nbsp;W I
            T H &nbsp;&nbsp;T E C H N O L O G Y
          </div>
        </div>
      </div>
    </el-carousel>
  </div>
</template>
<script>
export default {
  props: { statu: Boolean },
  data() {
    return {
      flag: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.flag = true;
    }, 1000);
  },
};
</script>
<style lang="scss" scoped>
.block {
  font-size: 0.5rem;
  position: relative;
  opacity: 0;
  transition: opacity 2000ms;
}
.blockClick {
  opacity: 1;
}
.swiper-text {
  width: 100%;

  position: absolute;
  bottom: 2.09rem;
  color: #fff;
  z-index: 9;
  font-size: 0.72rem;
  img {
    margin-right: 0.29rem;
  }
}
.swiper-eng {
  font-size: 0.2rem;
  color: #c2c2c2;
  font-family: Ebrima;
  font-weight: 600;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #fff;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #fff;
}
.swiper-img {
  width: 100%;
  height: 100%;
  border-radius: 0.1rem;
  overflow: hidden;
}
</style>